const environments = {
  signInLink: process.env.GATSBY_SIGN_IN_LINK,
  getPricingLink: process.env.GATSBY_GET_PRICING_LINK,
  facebookLink: process.env.GATSBY_FACEBOOK_LINK,
  instagramLink: process.env.GATSBY_INSTAGRAM_LINK,
  linkedInLink: process.env.GATSBY_LINKEDIN_LINK,
  whatsAppLink: process.env.GATSBY_WHATSAPP_LINK,
  summerLink: process.env.GATSBY_SUMMER_LINK,
  biaLink: process.env.GATSBY_BIA_LINK,
  bacuLink: process.env.GATSBY_BACU_LINK,
  vammoLink: process.env.GATSBY_VAMMO_LINK,
  rurallLink: process.env.GATSBY_RURALL_LINK,
  lucroLink: process.env.GATSBY_LUCRO_LINK,
  linndaLink: process.env.GATSBY_LINNDA_LINK,
  showBlog: process.env.GATSBY_SHOW_BLOG,
  createAccountLink: process.env.GATSBY_CREATE_ACCOUNT_LINK,
  earlyAccessFormLink: process.env.GATSBY_EARLY_ACCESS_FORM,
  measurementIdC4c7usCom: process.env.GATSBY_MEASUREMENT_ID_C4C7US_COM,
  earlyAccessFormEnglishLink: process.env.GATSBY_EARLY_ACCESS_FORM_ENGLISH,
  earlyAccessFormSpanishLink: process.env.GATSBY_EARLY_ACCESS_FORM_SPANISH,
};

export default environments;
