import React from "react";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import { Layout } from "./src/components/Layout/Layout";
import { Location } from "@gatsbyjs/reach-router";
import "./styles.scss";

type WrapPageElement =
  | GatsbyBrowser["wrapPageElement"]
  | GatsbySSR["wrapPageElement"];

export const wrapPageElement: WrapPageElement = ({ element, props }: any) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Layout location={location} {...props}>
            {element}
          </Layout>
        );
      }}
    </Location>
  );
};
